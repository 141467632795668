import { computed } from 'vue';
import { useGetCurrentRegionQuery } from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

type Props = {
  loading: boolean;
  currentRegion: number | null;
};

export const getCurrentRegionEnhancer = wrapComponent<object, Props>(
  (_props) => {
    const { result, loading } = useGetCurrentRegionQuery();

    return computed(() => ({
      loading: loading.value,
      currentRegion: result.value?.currentRegion || null
    }));
  }
);

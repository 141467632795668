
import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { asUTCWithZerotime } from '@/shared/util';
import UIButton from '@/shared/ui/buttons/Button.vue';
import UIInput from '@/shared/ui/forms/Input.vue';
import Loading from '@/shared/components/Loading.vue';
import {
  UpdateTeenFormObject,
  Props as KioskProps,
  Methods as KioskMethods
} from '../types';
import SchoolLookup from '@/shared/components/SchoolLookup';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { getLangForRegion } from '@/shared/language/lang';
import { UpdateTeenField } from '@/generated/graphql-types';

library.add(faCheck);

interface Data {
  formObject: UpdateTeenFormObject;
  isAddingSchool: boolean;
}

interface Props {
  currentRegion: KioskProps['currentRegion'];
  email: KioskProps['email'];
  person: KioskProps['person'];
  updateTeen: KioskProps['updateTeen'];
  event: KioskProps['event'];
  childSelected: KioskProps['childSelected'];
}

interface Methods {
  editTeenHandler: (
    callbackArgs: SubmitCallbackArgs<UpdateTeenFormObject>
  ) => Promise<void>;
  validate: (values: UpdateTeenFormObject) => Errors<UpdateTeenFormObject>;
}
type Errors<T> = {
  [k in keyof T]?: string;
};
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'EditTeen',
  components: {
    Loading,
    UIInput,
    UIButton,
    SimpleForm,
    Field,
    Error,
    SchoolLookup
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      formObject: {
        birthdate: null,
        school: null
      },
      isAddingSchool: false
    };
  },
  props: {
    currentRegion: {},
    email: {},
    person: {},
    updateTeen: {},
    event: {},
    childSelected: {}
  },
  async mounted() {
    if (
      this.person?.__typename === 'Person' &&
      this.person?.Teen &&
      !this.person.Teen.School &&
      this.event.School
    ) {
      this.isAddingSchool = true;
      await this.updateTeen(
        UpdateTeenField.HighSchoolId,
        this.event.School.schoolID.toString()
      );
      this.isAddingSchool = false;
    }
  },
  methods: {
    async editTeenHandler(
      callbackArgs: SubmitCallbackArgs<UpdateTeenFormObject>
    ) {
      if ('errors' in callbackArgs) return;

      const { birthdate, school } = callbackArgs.values;

      if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();
      if (birthdate) {
        await this.updateTeen(
          UpdateTeenField.BirthDate,
          asUTCWithZerotime(birthdate!) as string
        );
      }
      if (school) {
        await this.updateTeen(
          UpdateTeenField.HighSchoolId,
          school.schoolID.toString()
        );
      }
    },
    validate(values: UpdateTeenFormObject) {
      const errors: Errors<UpdateTeenFormObject> = {};
      if (
        this.person?.__typename === 'Person' &&
        this.person.Teen &&
        !this.person.Teen.birthDate &&
        !values.birthdate
      ) {
        errors.birthdate = 'Please enter your birthdate';
      }
      if (
        this.person?.__typename === 'Person' &&
        this.person.Teen &&
        !this.person.Teen.School &&
        !values.school
      ) {
        errors.school = 'Please select your school';
      }
      return errors;
    }
  }
});

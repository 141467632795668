
import Vue from 'vue';
import UIButton from '@/shared/ui/buttons/Button.vue';
import TicketPicker from './TicketPicker.vue';
import { Props as KioskProps } from '../types';
import { calculateAge } from '@/shared/util';
import { getLangForRegion } from '@/shared/language/lang';
import { RegistrationFragment } from '@/generated/graphql-types';

interface Data {
  lang: { [key: string]: string };
  ticketSelected: number | null;
}

interface Computed {
  isParentsShouldBeNotified: boolean;
  isMultipleTickets: boolean;
  currentRegistration: RegistrationFragment | null | undefined;
}

interface Props {
  event: KioskProps['event'];
  person: KioskProps['person'];
  markRegistration: KioskProps['markRegistration'];
  notifyParents: KioskProps['notifyParents'];
  currentRegion: KioskProps['currentRegion'];
}

interface Methods {
  selectTicket: (ticketId: number) => void;
  markRegistrationSubmit: () => Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MarkRegistration',
  components: {
    TicketPicker,
    UIButton
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      ticketSelected: null
    };
  },
  props: {
    event: {},
    person: {},
    markRegistration: {},
    notifyParents: {},
    currentRegion: {}
  },
  computed: {
    isParentsShouldBeNotified() {
      const isParentSigned =
        this.person?.__typename === 'Person' &&
        this.person.Parents!.filter(
          (parent) => parent.mediaConsentSigned && parent.dataConsentSigned
        ).length;
      return (
        calculateAge(
          this.person?.__typename === 'Person' &&
            this.person.Teen &&
            this.person.Teen.birthDate
        ) < 18 && !isParentSigned
      );
    },
    isMultipleTickets() {
      return this.event.EventTickets && this.event.EventTickets.length > 1;
    },
    currentRegistration() {
      if (this.person && this.person.__typename === 'Person') {
        return (
          this.person.Teen &&
          this.person.Teen.Registrations.find(
            (r) => r.eventId === this.event.eventId
          )
        );
      }
      return null;
    }
  },
  methods: {
    async markRegistrationSubmit() {
      await this.markRegistration(this.ticketSelected!);
    },
    selectTicket(ticketId) {
      this.ticketSelected = ticketId;
    }
  },
  mounted() {
    if (!this.isMultipleTickets) {
      this.ticketSelected = this.event.EventTickets[0].EventTicketID;
    }
    if (this.currentRegistration) {
      this.ticketSelected = this.currentRegistration.EventTicket.EventTicketID;
    }
  }
});


import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import { Data, Methods, Computed, Props } from './types';
import UIInput from '@/shared/ui/forms/Input.vue';
import UIButton from '@/shared/ui/buttons/Button.vue';
import Loading from '@/shared/components/Loading.vue';
import CreateTeen from './components/CreateTeen.vue';
import ParentFound from './components/ParentFound.vue';
import Finished from './components/Finished.vue';
import MarkConsent from './components/MarkConsent.vue';
import MarkAttendance from './components/MarkAttendance.vue';
import MarkRegistration from './components/MarkRegistration.vue';
import EditTeen from './components/EditTeen.vue';
import GetParentData from './components/GetParentData.vue';
import ScrollablePanel from '@/shared/components/scrollable-panel.vue';
import fullheight from '@/shared/directives/fullheight';
import {
  calculateAge,
  isJSU,
  validateEmail,
  normalizedEventDateTime
} from '@/shared/util';
import { getLangForRegion } from '@/shared/language/lang';
import {
  AttendanceStatus,
  PersonType,
  StatusType
} from '@/generated/graphql-types';
import config from '@/config';

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Kiosk',
  components: {
    UIInput,
    UIButton,
    Loading,
    CreateTeen,
    ParentFound,
    Finished,
    MarkConsent,
    MarkAttendance,
    MarkRegistration,
    EditTeen,
    GetParentData,
    ScrollablePanel,
    RouterLink
  },
  directives: {
    fullheight
  },
  data() {
    return {
      PersonType,
      localEmail: null,
      lang: getLangForRegion(this.currentRegion)
    };
  },
  props: {
    currentRegion: {},
    event: {},
    eventId: {},
    eventLoading: {},
    email: {},
    externalEmail: {},
    person: {},
    personLoading: {},
    childSelected: {},
    createTeen: {},
    updateTeen: {},
    updateTeenLoading: {},
    markAttendance: {},
    markRegistration: {},
    markConsent: {},
    markAttendanceAndConsent: {},
    createTeenLoading: {},
    markAttendanceLoading: {},
    markRegistrationLoading: {},
    markConsentLoading: {},
    markAttendanceAndConsentLoading: {},
    notifyParents: {},
    notifyParentsLoading: {},
    root: {}
  },
  computed: {
    isEmailValid() {
      return !!(this.email ? validateEmail(this.email) : true);
    },
    isLocalEmailValid() {
      return !!(this.localEmail ? validateEmail(this.localEmail) : true);
    },
    isJSU() {
      return isJSU(
        window.location.host,
        this.event &&
          this.event &&
          this.event.EventSubType &&
          this.event.EventSubType.eventSubTypeId
      );
    },
    attendedEvent() {
      if (!this.person) return false;

      return !!(
        this.person.__typename === 'Person' &&
        this.person.Teen?.Attendances.filter(
          (a) => a.status === AttendanceStatus.Attended
        ).find((e) => e.eventId === this.event.eventId)
      );
    },
    registeredEvent() {
      if (!this.person) return false;

      return !!(
        this.person.__typename === 'Person' &&
        this.person.Teen &&
        this.person.Teen.Registrations.filter(
          (r) => r.status !== StatusType.Cancelled
        ).find((r) => r.eventId === this.event.eventId)
      );
    },
    consented() {
      if (!this.person) return false;
      return (
        this.person.__typename === 'Person' &&
        this.person.mediaConsentSigned !== null &&
        this.person.mediaConsentSigned !== undefined &&
        this.person.dataConsentSigned !== null &&
        this.person.dataConsentSigned !== undefined
      );
    },
    eventImage() {
      return this.event
        ? `${config.teenApi}/event/${this.event.eventId}/image`
        : '';
    },
    component() {
      if (
        this.personLoading ||
        this.createTeenLoading ||
        this.updateTeenLoading ||
        this.markAttendanceLoading ||
        this.markRegistrationLoading ||
        this.markConsentLoading ||
        this.markAttendanceAndConsentLoading ||
        this.notifyParentsLoading
      ) {
        return 'Loading';
      }
      if (!this.isEmailValid) {
        return 'div';
      }
      if (!this.person) {
        return 'CreateTeen';
      }
      if (
        this.person.__typename === 'Person' &&
        this.person.type &&
        this.person.type === PersonType.Parent
      ) {
        return 'ParentFound';
      }
      if (
        this.person.__typename === 'Person' &&
        this.person.Teen &&
        (!this.person.Teen.birthDate || !this.person.Teen.School)
      ) {
        return 'EditTeen';
      }
      // For now only Canada has the parent flow
      // if has teen < 18 and no parents
      if (
        ((this.event && this.event.regionId === 8) ||
          this.currentRegion === 8) &&
        this.person.__typename === 'Person' &&
        this.person.Teen &&
        calculateAge(this.person?.Teen?.birthDate) < 18 &&
        !this.person?.Parents.length
      ) {
        return 'GetParentData';
      }
      // TODO: consent flow
      // if (this.attendedEvent && this.consented) {
      if (
        (!this.event.registrationRequired && this.attendedEvent) ||
        (this.event.registrationRequired && this.registeredEvent)
      ) {
        return 'Finished';
      }
      // TODO: consent flow
      // if (!this.consented) {
      //   return 'MarkConsent';
      // }
      // if (!this.attendedEvent && this.consented) {
      if (!this.event.registrationRequired && !this.attendedEvent) {
        return 'MarkAttendance';
      }
      if (this.event.registrationRequired && !this.registeredEvent) {
        return 'MarkRegistration';
      }

      return 'div';
    },
    eventDate() {
      return normalizedEventDateTime(
        this.event.startDate,
        this.event.endDate,
        this.event.timeZone
      );
    }
  },
  methods: {
    selectChild(child) {
      if (child.EmailAddresses[0] && child.EmailAddresses[0].email) {
        this.$emit('email', child.EmailAddresses[0].email);
        this.localEmail = child.EmailAddresses[0].email;
        this.$emit('childSelected', null);
      } else {
        this.$emit('childSelected', child);
      }
    },
    selectEmail(email) {
      this.localEmail = email;
      this.$emit('email', email);
      this.$emit('childSelected', null);
    },
    handleEmailChanged() {
      if (this.localEmail && this.localEmail === this.email) {
        return;
      }
      this.$emit('email', this.localEmail || '');
      this.$emit('childSelected', null);
    },
    clearEmail() {
      this.localEmail = null;
      this.$emit('email', '');
      this.$emit('person', null);
      this.$emit('childSelected', null);
    }
  }
});

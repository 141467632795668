import { acceptProps, compose } from 'vue-compose';
import Vue, { Component } from 'vue';
import { getOptions, normalizeProps } from '../../util/index';

const withTerm = (Component: Component) => {
  const props = normalizeProps(getOptions(Component).props);

  return Vue.extend({
    name: 'withTerm',
    props,
    data() {
      return {
        localTerm: ''
      };
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          term: this.localTerm,
          setTerm: (term: string) => (this.localTerm = term),
          getTerm: () => this.localTerm
        },
        on: this.$listeners
      });
    }
  });
};

export default (enhancer: Component | ((component: Component) => Component)) =>
  (Component: Component) =>
    compose(acceptProps(['value']), withTerm, enhancer)(Component);

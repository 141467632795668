
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import UIButton from '@/shared/ui/buttons/Button.vue';
import { Props as ExternalProps } from '../../';

library.add(faTimes);

interface Data {
  loading: boolean;
  removing: boolean;
}

interface Methods {
  handleMarkAttendance: () => Promise<void>;
  handleMarkStaffAttendance: () => Promise<void>;
  handleRemoveAttendance: () => Promise<void>;
  handleRemoveStaffAttendance: () => Promise<void>;
}

type Props = Pick<
  ExternalProps,
  | 'teen'
  | 'staff'
  | 'eventId'
  | 'markAttendance'
  | 'markStaffAttendance'
  | 'removeAttendance'
  | 'removeStaffAttendance'
>;

export default Vue.extend<Data, Methods, {}, Props>({
  components: { UIButton },
  props: {
    teen: {},
    staff: {},
    eventId: {},
    markAttendance: {},
    markStaffAttendance: {},
    removeAttendance: {},
    removeStaffAttendance: {}
  },
  data() {
    return {
      loading: false,
      removing: false
    };
  },
  methods: {
    async handleMarkAttendance() {
      this.loading = true;
      try {
        await this.markAttendance(this.eventId, this.teen.personID);
        this.$emit('refreshEvent');
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
    async handleMarkStaffAttendance() {
      this.loading = true;
      try {
        await this.markStaffAttendance(this.eventId, this.staff.personID);
        this.$emit('refreshEvent');
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
    async handleRemoveAttendance() {
      this.removing = true;
      try {
        await this.removeAttendance(this.teen.AttendanceID);
        this.$emit('refreshEvent');
        this.removing = false;
      } catch (e) {
        this.removing = false;
        throw e;
      }
    },
    async handleRemoveStaffAttendance() {
      this.removing = true;
      try {
        await this.removeStaffAttendance(this.eventId, this.staff.personID);
        this.$emit('refreshEvent');
        this.removing = false;
      } catch (e) {
        this.removing = false;
        throw e;
      }
    }
  }
});


import Vue from 'vue';
import UISelect from '@/shared/ui/forms/FancySelect';
import { Staff } from '@/generated/graphql-types';

interface Props {
  regionId: number;
  disabled: boolean;
  clearable: boolean;
  staff: Staff[];
  loading: boolean;
  value: Staff[] | Staff | number | null;
  multiple: boolean;
  filterIds: number[];
}

interface Computed {
  valueAsArray: Staff[];
  filteredStaff: Staff[];
}

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'StaffLookup',
  components: { UISelect },
  props: {
    regionId: {},
    disabled: { default: false },
    clearable: { default: false },
    staff: {},
    loading: { default: false },
    value: {},
    multiple: { default: false },
    filterIds: { default: () => [] }
  },
  computed: {
    valueAsArray(): Staff[] {
      if (!this.value) return [];

      const value: Array<number | Staff> = Array.isArray(this.value)
        ? this.value
        : [this.value];

      return value
        .map((staff) =>
          typeof staff === 'object'
            ? staff
            : this.staff.find((x) => x.staffID === staff)!
        )
        .filter((s) => s);
    },
    filteredStaff(): Staff[] {
      return this.staff.filter((s) => {
        return !this.filterIds.includes(s.staffID);
      });
    }
  }
});

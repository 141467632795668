interface Config {
  issuer: string;
  client_id: string;
  graphql: string;
  subscriptions: string;
  ncsyApi: string;
  teenApi: string;
}

const config: Config = {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  issuer: process.env.VUE_APP_ISSUER!,
  client_id: process.env.VUE_APP_CLIENT_ID!,
  graphql: process.env.VUE_APP_GRAPHQL!,
  subscriptions: process.env.VUE_APP_SUBSCRIPTIONS!,
  ncsyApi: process.env.VUE_APP_NCSYAPI!,
  teenApi: process.env.VUE_APP_TEENAPI!
  /* eslint-enable  @typescript-eslint/no-non-null-assertion */
};

export default config;

import { computed } from 'vue';
import { withData, compose } from 'vue-compose';
import TeenLookupComponent from './TeenLookup.vue';
import { toCompactTeens } from './utils';
import { CompactTeen as CompactTeenType } from './shared/CompactTeen';
import { wrapComponent } from '@/shared/components/hoc';
import {
  TeenLookupQuery,
  TeenLookupQueryVariables,
  useTeenLookupQuery
} from '@/generated/graphql-types';

interface Props {
  regionId: number;
  isAlumni: boolean;
  term: string;
  showTeenInfo: boolean;
  multiple: boolean;
  withInfo: boolean;
}

interface TeenLookupProps {
  teens: CompactTeen[] | TeenLookupQuery['teens']['teens'];
  searching: boolean;
}

const teenLookupEnhancer = wrapComponent<Props, TeenLookupProps>((props) => {
  const variables = computed<TeenLookupQueryVariables>(() => ({
    regionID: props.regionId,
    showAlumni: props.isAlumni,
    term: props.term
  }));
  const enabled = computed(() => !!props.term);

  const { result, loading } = useTeenLookupQuery(variables, { enabled });

  return computed(() => ({
    searching: loading.value,
    teens: result.value?.teens
      ? props.withInfo
        ? toCompactTeens(result.value.teens.teens)
        : result.value.teens.teens
      : []
  }));
});

export default compose(
  withData<any, any>({
    term: {
      initialValue: null
    }
  }),
  teenLookupEnhancer
)(TeenLookupComponent);

export type CompactTeen = CompactTeenType;

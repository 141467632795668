import axios, { AxiosError, Method, ResponseType } from 'axios';
import config from '@/config';

export default async (
  url: string,
  data = {},
  method: Method = 'get',
  responseType: ResponseType = 'json'
) => {
  const api = config.ncsyApi;

  try {
    const result = await axios({
      method,
      url: `${api}/${url}`,
      data,
      responseType,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return result;
  } catch (e) {
    const error = e as AxiosError;
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      url.toLowerCase() !== 'authentication'
    ) {
      // tslint:disable-next-line
      console.log(e);
      return { data: 401 };
    }

    return { data: {} };
  }
};


import Vue from 'vue';
import { MatchFirst, Route, HistoryRouter } from 'vue-component-router';
import Root from '@/pages/Root';
import TeenRoot from '@/pages/TeenRoot';
import SeriesRoot from '@/pages/SeriesRoot';
import AdvisorRoot from '@/pages/AdvisorRoot';
import Oidc from '@/shared/oidc/oidc.vue';

export default Vue.extend({
  name: 'app',
  props: ['store', 'userManager'],
  components: {
    Root,
    TeenRoot,
    AdvisorRoot,
    HistoryRouter,
    MatchFirst,
    Route,
    Oidc,
    SeriesRoot
  },
  methods: {
    getAuthState(authenticating: boolean, redirecting: boolean) {
      if (authenticating) return 'authenticating';
      if (redirecting) return 'redirecting';
    }
  }
});

import { computed } from 'vue';
import { acceptProps, compose, withData } from 'vue-compose';
import cloneDeep from 'lodash/cloneDeep';
import Kiosk from './Kiosk.vue';
import { Props } from './types';
import { isJSU, validateEmail } from '@/shared/util';
import { getCurrentRegionEnhancer } from '@/shared/enhancers/getCurrentRegionEnhancer';
import {
  Attendance,
  CreateTeenInput,
  EntityUnionEnum,
  EventParticipationSource,
  LookupByEmailDocument,
  LookupByEmailQuery,
  LookupByEmailQueryVariables,
  UpdateTeenField,
  useCreateTeenMutation,
  useGetEventByIdQuery,
  useLookupByEmailQuery,
  useMarkAttendanceAndConsentMutation,
  useMarkAttendanceMutation,
  useMarkConsentMutation,
  useMarkRegistrationMutation,
  useNotifyParentsMutation,
  useUpdateTeenMutation
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

type Person = Extract<
  LookupByEmailQuery['searchDuplicateEmails'],
  { personID: number }
>;
type InnerProps = Pick<
  Props,
  'childSelected' | 'email' | 'eventId' | 'event' | 'person'
>;

const lookupEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const variables = computed<LookupByEmailQueryVariables>(() => ({
    email: props.email || '',
    type: EntityUnionEnum.Person
  }));
  const enabled = computed(() => !!(props.email && validateEmail(props.email)));
  const { result, loading } = useLookupByEmailQuery(variables, {
    enabled,
    fetchPolicy: 'network-only'
  });

  return computed(() => ({
    personLoading: loading.value,
    person: props.email
      ? props.childSelected ||
        (result.value && (result.value.searchDuplicateEmails as Person))
      : null
  }));
});

const getEventEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const variables = computed(() => ({
    eventId: props.eventId
  }));
  const { result, loading } = useGetEventByIdQuery(variables);

  return computed(() => ({
    eventLoading: loading.value,
    event: result.value?.event
  }));
});

const createTeenEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: createTeen, loading } = useCreateTeenMutation({
    update: (cache, { data }) => {
      if (data && data.addTeenFromAttendanceApp && props.email) {
        cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>({
          query: LookupByEmailDocument,
          variables: {
            email: props.email,
            type: EntityUnionEnum.Person
          },
          data: {
            searchDuplicateEmails: {
              ...data.addTeenFromAttendanceApp.Person,
              Children: [],
              Teen: {
                personID: data.addTeenFromAttendanceApp.personID,
                Attendances: data.addTeenFromAttendanceApp.Attendances || [],
                Registrations:
                  data.addTeenFromAttendanceApp.Registrations || [],
                birthDate: data.addTeenFromAttendanceApp.birthDate,
                School: data.addTeenFromAttendanceApp.School,
                __typename: 'Teen'
              }
            }
          }
        });
      }
    }
  });

  return computed(() => ({
    createTeenLoading: loading.value,
    createTeen: (input: CreateTeenInput) =>
      createTeen({
        input: {
          ...input,
          isJewish: true,
          chapterId: (props.event.Chapter || { chapterId: undefined })
            .chapterId,
          schoolID: input.schoolID
            ? input.schoolID
            : (props.event.School || { schoolID: undefined }).schoolID,
          originSite: isJSU(
            window.location.host,
            props.event &&
              props.event.EventSubType &&
              props.event.EventSubType.eventSubTypeId
          )
            ? 1
            : 0
        }
      })
  }));
});

const updateTeenEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: updateTeen, loading } = useUpdateTeenMutation({
    update: (cache, { data }) => {
      if (data && data.updateTeen && props.email) {
        cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>({
          query: LookupByEmailDocument,
          variables: {
            email: props.email,
            type: EntityUnionEnum.Person
          },
          data: {
            searchDuplicateEmails: {
              ...data.updateTeen.Person,
              __typename: 'Person',
              Children: [],
              Teen: {
                personID: data.updateTeen.personID,
                Attendances: data.updateTeen.Attendances || [],
                Registrations: data.updateTeen.Registrations || [],
                birthDate: data.updateTeen.birthDate,
                School: data.updateTeen.School,
                __typename: 'Teen'
              }
            }
          }
        });
      }
    }
  });

  return computed(() => ({
    updateTeenLoading: loading.value,
    updateTeen: (fieldName: UpdateTeenField, value: string) =>
      updateTeen({
        personID: (props.person as Person).personID,
        fieldName,
        value
      })
  }));
});

const markAttendanceEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: markAttendance, loading } = useMarkAttendanceMutation({
    update: (cache, { data: markAttendance }) => {
      if (markAttendance && markAttendance.addAttendance && props.email) {
        const data = cache.readQuery<
          LookupByEmailQuery,
          LookupByEmailQueryVariables
        >({
          query: LookupByEmailDocument,
          variables: {
            email: props.email,
            type: EntityUnionEnum.Person
          }
        });

        if (data && data.searchDuplicateEmails) {
          const searchDuplicateEmails = cloneDeep(
            data.searchDuplicateEmails
          ) as Person;
          const attendance: Pick<
            Attendance,
            'attendanceId' | 'eventId' | 'status' | '__typename'
          > = {
            attendanceId: markAttendance.addAttendance.attendanceId,
            eventId: markAttendance.addAttendance.eventId,
            status: markAttendance.addAttendance.status,
            __typename: 'Attendance'
          };
          if (searchDuplicateEmails.Teen) {
            if (
              !(searchDuplicateEmails.Teen.Attendances || []).find(
                (a) => a.eventId === markAttendance.addAttendance.eventId
              )
            ) {
              searchDuplicateEmails.Teen.Attendances.push(attendance);
            }
          }
          if (searchDuplicateEmails.Children.length > 0) {
            const updatedChildIdx = searchDuplicateEmails.Children.findIndex(
              (c) => c.personID === (props.person as Person).personID
            );
            if (updatedChildIdx > -1) {
              const updatedChild = {
                ...searchDuplicateEmails.Children[updatedChildIdx]
              };
              if (
                !(updatedChild.Teen?.Attendances || []).find(
                  (a) => a.eventId === markAttendance.addAttendance.eventId
                )
              ) {
                updatedChild.Teen?.Attendances.push(attendance);
                searchDuplicateEmails.Children.splice(
                  updatedChildIdx,
                  1,
                  updatedChild
                );
              }
            }
          }
          cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>({
            query: LookupByEmailDocument,
            variables: {
              email: props.email,
              type: EntityUnionEnum.Person
            },
            data: {
              ...data,
              searchDuplicateEmails
            }
          });
        }
      }
    }
  });

  return computed(() => ({
    markAttendanceLoading: loading.value,
    markAttendance: () =>
      markAttendance({
        input: {
          eventId: props.event.eventId,
          teenId: (props.person as Person).personID,
          source: EventParticipationSource.AttendanceApp
        }
      })
  }));
});

const markRegistrationEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: markRegistration, loading } = useMarkRegistrationMutation({
    update: (cache, { data: markRegistration }) => {
      if (
        markRegistration &&
        markRegistration.registerTeenWithCovidQuestionnaire &&
        markRegistration.registerTeenWithCovidQuestionnaire.registration &&
        props.email
      ) {
        const data = cache.readQuery<
          LookupByEmailQuery,
          LookupByEmailQueryVariables
        >({
          query: LookupByEmailDocument,
          variables: {
            email: props.email,
            type: EntityUnionEnum.Person
          }
        });

        if (data && data.searchDuplicateEmails) {
          const registration =
            markRegistration.registerTeenWithCovidQuestionnaire.registration;
          const searchDuplicateEmails = cloneDeep(
            data.searchDuplicateEmails
          ) as Person;
          if (searchDuplicateEmails.Teen) {
            if (
              !(searchDuplicateEmails.Teen.Registrations || []).find(
                (r) => r.eventId === registration.eventId
              )
            ) {
              searchDuplicateEmails.Teen.Registrations = [
                ...searchDuplicateEmails.Teen.Registrations,
                registration
              ];
            }
          }
          if (searchDuplicateEmails.Children.length > 0) {
            const updatedChildIdx = searchDuplicateEmails.Children.findIndex(
              (c) => c.personID === (props.person as Person).personID
            );
            if (updatedChildIdx > -1) {
              const updatedChild =
                searchDuplicateEmails.Children[updatedChildIdx];
              if (
                !(updatedChild.Teen?.Registrations || []).find(
                  (a) => a.eventId === registration.eventId
                )
              ) {
                (updatedChild.Teen?.Registrations || []).push(registration);
                searchDuplicateEmails.Children.splice(
                  updatedChildIdx,
                  1,
                  updatedChild
                );
              }
            }
          }
          cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>({
            query: LookupByEmailDocument,
            variables: {
              email: props.email,
              type: EntityUnionEnum.Person
            },
            data: {
              ...data,
              searchDuplicateEmails
            }
          });
        }
      }
    }
  });

  return computed(() => ({
    markRegistrationLoading: loading.value,
    markRegistration: (eventTicketID: number) =>
      markRegistration({
        input: {
          eventID: props.event.eventId,
          teenID: (props.person as Person).personID,
          eventTicketID,
          source: EventParticipationSource.AttendanceApp
        }
      })
  }));
});

const markConsentEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: markConsent, loading } = useMarkConsentMutation({
    update: (cache, { data: markConsent }) => {
      if (markConsent && markConsent.markTeenConsent && props.email) {
        const data = cache.readQuery<
          LookupByEmailQuery,
          LookupByEmailQueryVariables
        >({
          query: LookupByEmailDocument,
          variables: {
            email: props.email,
            type: EntityUnionEnum.Person
          }
        });

        if (data && data.searchDuplicateEmails) {
          const searchDuplicateEmails = data.searchDuplicateEmails as Person;

          cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>({
            query: LookupByEmailDocument,
            variables: {
              email: props.email,
              type: EntityUnionEnum.Person
            },
            data: {
              ...data,
              searchDuplicateEmails: {
                ...searchDuplicateEmails,
                dataConsentSigned: true,
                mediaConsentSigned: true
              }
            }
          });
        }
      }
    }
  });

  return computed(() => ({
    markConsentLoading: loading.value,
    markConsent: () =>
      markConsent({
        teenId: (props.person as Person).personID,
        data: {
          mediaConsentSigned: true,
          dataConsentSigned: true
        }
      })
  }));
});

const markAttendanceAndConsentEnhancer = wrapComponent<InnerProps, Props>(
  (props) => {
    const { mutate: markAttendanceAndConsent, loading } =
      useMarkAttendanceAndConsentMutation({
        update: (cache, { data: markAttendanceAndConsent }) => {
          if (
            markAttendanceAndConsent &&
            markAttendanceAndConsent.markTeenConsent &&
            props.email
          ) {
            const data = cache.readQuery<
              LookupByEmailQuery,
              LookupByEmailQueryVariables
            >({
              query: LookupByEmailDocument,
              variables: {
                email: props.email,
                type: EntityUnionEnum.Person
              }
            });

            if (data && data.searchDuplicateEmails) {
              const searchDuplicateEmails = cloneDeep(
                data.searchDuplicateEmails
              ) as Person;

              searchDuplicateEmails.dataConsentSigned = true;
              searchDuplicateEmails.mediaConsentSigned = true;

              if (
                !(searchDuplicateEmails.Teen?.Attendances || []).find(
                  (a) =>
                    a.eventId === markAttendanceAndConsent.addAttendance.eventId
                )
              ) {
                searchDuplicateEmails.Teen?.Attendances.push({
                  attendanceId:
                    markAttendanceAndConsent.addAttendance.attendanceId,
                  eventId: markAttendanceAndConsent.addAttendance.eventId,
                  status: markAttendanceAndConsent.addAttendance.status,
                  __typename: 'Attendance'
                });
              }

              cache.writeQuery<LookupByEmailQuery, LookupByEmailQueryVariables>(
                {
                  query: LookupByEmailDocument,
                  variables: {
                    email: props.email,
                    type: EntityUnionEnum.Person
                  },
                  data: { ...data, searchDuplicateEmails }
                }
              );
            }
          }
        }
      });

    return computed(() => ({
      markAttendanceAndConsentLoading: loading.value,
      markAttendanceAndConsent: () =>
        markAttendanceAndConsent({
          personId: (props.person as Person).personID,
          eventId: props.event.eventId,
          data: {
            mediaConsentSigned: true,
            dataConsentSigned: true
          }
        })
    }));
  }
);

const notifyParentsEnhancer = wrapComponent<InnerProps, Props>((props) => {
  const { mutate: notifyParents, loading } = useNotifyParentsMutation({});

  return computed(() => ({
    notifyParentsLoading: loading.value,
    notifyParents: () =>
      notifyParents({
        personId: (props.person as Person).personID,
        originSite: isJSU(
          window.location.host,
          props.event &&
            props.event.EventSubType &&
            props.event.EventSubType.eventSubTypeId
        )
          ? 1
          : 0
      })
  }));
});

export default compose(
  withData({
    email: {
      initialValue: (props) => props.externalEmail || ''
    },
    childSelected: {
      initialValue: null
    }
  }),
  acceptProps(['eventId']),
  getCurrentRegionEnhancer,
  getEventEnhancer,
  lookupEnhancer,
  createTeenEnhancer,
  updateTeenEnhancer,
  markAttendanceEnhancer,
  markRegistrationEnhancer,
  markConsentEnhancer,
  markAttendanceAndConsentEnhancer,
  notifyParentsEnhancer
)(Kiosk);

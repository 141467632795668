
import Vue from 'vue';
import UIButton from '@/shared/ui/buttons/Button.vue';
import UICheckbox from '@/shared/ui/forms/Checkbox.vue';
import { Props as KioskProps, Computed as KioskComputed } from '../types';
import { calculateAge } from '@/shared/util';
import { getLangForRegion } from '@/shared/language/lang';

interface Data {
  mediaConsent: boolean;
  dataConsent: boolean;
}

interface Methods {
  handler: () => Promise<void>;
}

interface Computed {
  longOrganizationName: string;
  shortOrganizationName: string;
  isParentsShouldBeNotified: boolean;
}
interface Props {
  event: KioskProps['event'];
  person: KioskProps['person'];
  markConsent: KioskProps['markConsent'];
  attendedEvent: KioskComputed['attendedEvent'];
  notifyParents: KioskProps['notifyParents'];
  currentRegion: KioskProps['currentRegion'];
  isJSU: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MarkConsent',
  components: {
    UICheckbox,
    UIButton
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      mediaConsent: false,
      dataConsent: false
    };
  },
  props: {
    markConsent: {},
    attendedEvent: {},
    event: {},
    person: {},
    notifyParents: {},
    currentRegion: {},
    isJSU: {}
  },
  methods: {
    async handler() {
      if (this.attendedEvent && this.isParentsShouldBeNotified) {
        await this.notifyParents();
      }
      await this.markConsent();
    }
  },
  computed: {
    longOrganizationName() {
      return window.location.host.endsWith('jsu.org') ||
        this.event.EventSubType.eventSubTypeId === 290
        ? 'The Jewish Student Union'
        : 'NCSY';
    },
    shortOrganizationName() {
      return window.location.host.endsWith('jsu.org') ||
        this.event.EventSubType.eventSubTypeId === 290
        ? 'JSU'
        : 'NCSY';
    },
    isParentsShouldBeNotified() {
      const isParentSigned =
        this.person?.__typename === 'Person' &&
        this.person.Parents.filter(
          (parent) => parent.mediaConsentSigned && parent.dataConsentSigned
        ).length;
      return (
        calculateAge(
          this.person?.__typename === 'Person' &&
            this.person.Teen &&
            this.person.Teen.birthDate
        ) < 18 && !isParentSigned
      );
    }
  }
});


import Vue from 'vue';
import VueSelect from 'vue-select';
import isEqual from 'lodash/isEqual';

export default Vue.extend({
  name: 'FancySelect',
  inheritAttrs: false,
  props: {
    options: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    value: {
      type: [Object, String, Number, Array]
    },
    clearable: {
      type: Boolean,
      default: true
    },
    resetAfter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueAsArray() {
      if (!this.value) return [];
      const value: any = Array.isArray(this.value) ? this.value : [this.value];
      return value;
    },
    props(): any {
      return {
        ...this.$attrs,
        ...this.$props,
        value: this.valueAsArray
      };
    }
  },
  methods: {
    async handleInput(value: any) {
      if (isEqual(this.value, value)) return;

      this.$emit('input', value);
      this.$emit('blur', value);
      this.$emit('change', value);

      if (this.resetAfter) {
        // @ts-ignore

        this.$refs.multiselect.mutableValue = null;
      }
    }
  },
  components: {
    VueSelect
  }
});

import SeriesRoot from './SeriesRoot.vue';
import Vue, { Component } from 'vue';
import { compose } from 'vue-compose';
import LogRocket from 'logrocket';
import { decodeURLParams, getOptions, normalizeProps } from '@/shared/util';
import { User, UserSettings } from 'oidc-client';
import ncsyApi from '@/modules/ncsyApi';
import store from '@/store';
import { getCurrentRegionEnhancer } from '@/shared/enhancers/getCurrentRegionEnhancer';
import { setCurrentRegionEnhancer } from '@/shared/enhancers/setCurrentRegionEnhancer';
import { Props } from './types';

interface DecodedSeriesTokenResponse {
  RegionId: number;
  TokenResponse: UserSettings;
  Email?: string;
  EmailFound: boolean;
  NoRelevantEvents: boolean;
  EventId?: number;
}

const getAuthToken = async ({
  emailToken,
  token
}: {
  emailToken: string | undefined;
  token: string;
}) => {
  const result = await ncsyApi(
    'authentication/attendanceapp/series',
    {
      token,
      emailToken
    },
    'post'
  );

  return result.data;
};

type Data = {
  isLoading: boolean;
  isError: boolean;
  email: string | null;
  outerEventId: number | null;
  outerNoRelevantEvents: boolean;
};

const getUserEnhancer = (Component: Component) => {
  const props = { ...normalizeProps(getOptions(Component).props) };
  return Vue.extend<Data, object, object, Props>({
    name: 'WithUser',
    props,
    data() {
      return {
        isLoading: true,
        isError: false,
        email: null,
        outerEventId: null,
        outerNoRelevantEvents: false
      };
    },
    async created() {
      this.isLoading = true;
      try {
        const token = decodeURLParams(this.queryString).token;
        const emailToken = decodeURLParams(this.queryString).email;
        const result: DecodedSeriesTokenResponse = await getAuthToken({
          token,
          emailToken
        });
        const user = new User({ ...result.TokenResponse });
        if (result.Email) {
          this.email = result.Email;
        }
        await store.dispatch('oidcStore/storeOidcUser', user);
        LogRocket.identify('attendanceapp@ou.org', {
          name: 'Attendance App User'
        });
        await this.setCurrentRegion(result.RegionId);
        this.outerEventId = result.EventId || null;
        if (result.NoRelevantEvents) {
          this.outerNoRelevantEvents = true;
          this.isError = true;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isError = true;
        return;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          userLoading: this.isLoading,
          error: this.isError,
          externalEmail: this.email,
          eventId: this.outerEventId,
          noRelevantEvents: this.outerNoRelevantEvents
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(
  getCurrentRegionEnhancer,
  setCurrentRegionEnhancer,
  getUserEnhancer
)(SeriesRoot);


import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import {
  splitName,
  validateName,
  validateNumber,
  asUTCWithZerotime
} from '@/shared/util';
import * as RadioGroup from '@/shared/ui/forms/radio-group';
import UIButton from '@/shared/ui/buttons/Button.vue';
import UICheckbox from '@/shared/ui/forms/Checkbox.vue';
import UIInput from '@/shared/ui/forms/Input.vue';
import UIRadio from '@/shared/ui/forms/Radio.vue';
import GraduationYearPicker from '@/shared/ui/GraduationYearPicker.vue';
import PhoneInput from '@/shared/ui/forms/Phone.vue';
import SchoolLookup from '@/shared/components/SchoolLookup';
import {
  CreateTeenFormObject,
  Props as KioskProps,
  Methods as KioskMethods
} from '../types';
import { getLangForRegion } from '@/shared/language/lang';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { CreateTeenInput } from '@/generated/graphql-types';

library.add(faCheck);

interface Data {
  formObject: CreateTeenFormObject;
}

interface Props {
  currentRegion: KioskProps['currentRegion'];
  email: KioskProps['email'];
  createTeen: KioskProps['createTeen'];
  event: KioskProps['event'];
  selectEmail: KioskMethods['selectEmail'];
}

interface Methods {
  addNewTeenHandler: (
    callbackArgs: SubmitCallbackArgs<CreateTeenFormObject>
  ) => Promise<void>;
  validate: (values: CreateTeenFormObject) => Errors<CreateTeenFormObject>;
  handleGrade: (
    event: any,
    setValue: (field: string, value: any) => void,
    values: CreateTeenFormObject
  ) => void;
}
type Errors<T> = {
  [k in keyof T]?: string;
};
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'CreateTeen',
  components: {
    UIInput,
    UIRadio,
    UIButton,
    UICheckbox,
    SimpleForm,
    Field,
    Error,
    ...RadioGroup,
    GraduationYearPicker,
    PhoneInput,
    SchoolLookup
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      formObject: {
        parentFullName: null,
        parentGender: null,
        childEmail: null,
        gender: null,
        fullName: null,
        grade: null,
        cellPhone: null,
        birthdate: null,
        zip: null,
        school: null
      }
    };
  },
  props: {
    currentRegion: {},
    email: {},
    event: {},
    createTeen: {},
    selectEmail: {}
  },
  methods: {
    async addNewTeenHandler(
      callbackArgs: SubmitCallbackArgs<CreateTeenFormObject>
    ) {
      if ('errors' in callbackArgs) return;
      const { firstName, lastName } = splitName(callbackArgs.values.fullName!);

      const {
        gender,
        cellPhone,
        grade,
        birthdate,
        zip,
        school,
        parentFullName,
        parentGender,
        childEmail
      } = callbackArgs.values;

      const teenInput: CreateTeenInput = {
        firstName,
        lastName,
        regionId: this.currentRegion,
        email: this.email,
        gender: gender!,
        cellNumber: cellPhone,
        graduationYear: grade!.year,
        birthdate: asUTCWithZerotime(birthdate!),
        zip,
        schoolID: school && school.schoolID
      };

      if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();
      await this.createTeen(teenInput);
    },
    validate(values: CreateTeenFormObject) {
      const errors: Errors<CreateTeenFormObject> = {};

      if (!values.fullName || !validateName(values.fullName)) {
        errors.fullName = `Please enter your first and last name`;
      }

      if (values.gender === null) {
        errors.gender = `Please select gender`;
      }
      if (!values.grade) {
        errors.grade = `Please select your grade`;
      }
      if (!values.cellPhone) {
        errors.cellPhone = `Please enter your cell phone number`;
      }
      if (
        values.cellPhone &&
        (values.cellPhone.length !== 10 || !validateNumber(values.cellPhone!))
      ) {
        errors.cellPhone = 'Phone number should be 10 digits length';
      }
      if (!values.birthdate) {
        errors.birthdate = `Please enter your birthdate`;
      }
      if (this.event.isRegionWide && !values.zip) {
        errors.zip = `Please enter your zipcode`;
      }
      if (!values.school && !this.event.School) {
        errors.school = `Please select your school`;
      }
      return errors;
    },
    handleGrade(event: any, setValue, values: CreateTeenFormObject) {
      if (!values.birthdate) {
        setValue('birthdate', event.year - 6 - 12 + '-01-01');
      }
    }
  }
});


import Vue from 'vue';
import { Props as KioskProps, Methods as KioskMethods } from '../types';
import { getLangForRegion } from '@/shared/language/lang';

interface Props {
  currentRegion: KioskProps['currentRegion'];
  person: KioskProps['person'];
  selectChild: KioskMethods['selectChild'];
}
export default Vue.extend<{}, {}, {}, Props>({
  name: 'ParentFound',
  props: {
    currentRegion: {},
    person: {},
    selectChild: {}
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion)
    };
  }
});


import Vue from 'vue';

interface Data {
  listeners: Record<string, Function | Function[]>;
}

interface Props {
  value: string | number;
  placeholder: string;
  width: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  name: 'UIInput',
  props: {
    value: {},
    placeholder: {},
    width: {
      default: '100%'
    }
  },
  data() {
    return {
      listeners: {}
    };
  },
  created() {
    this.listeners = {
      ...this.$listeners,
      input: (e: { target: HTMLInputElement }) =>
        this.$emit('input', e.target.value)
    };
  }
});

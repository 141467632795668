
import Vue from 'vue';
import UISelect from './forms/Select.vue';
import { graduationYears } from '@/shared/util';
import { getLangForRegion } from '@/shared/language/lang';

interface Data {
  graduationYearsOptions: ReturnType<typeof graduationYears>;
  lang: ReturnType<typeof getLangForRegion>;
}

interface Methods {
  onInput: (event: any) => void;
}

interface Props {
  value: number;
  currentRegion: number;
}

export default Vue.extend<Data, Methods, {}, Props>({
  data() {
    return {
      graduationYearsOptions: graduationYears(),
      lang: getLangForRegion(this.currentRegion)
    };
  },
  components: {
    UISelect
  },
  props: {
    value: {},
    currentRegion: {}
  },
  methods: {
    onInput($event: any) {
      this.$emit('selected', $event);
      this.$emit('input', $event);
    }
  }
});

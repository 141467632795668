import get from 'lodash/get';
import merge from 'lodash/merge';
import Vue, { VNode, CreateElement } from 'vue';

function injectProps(component: VNode, props: object) {
  if (!component.componentOptions) {
    return component;
  }

  const options = component.componentOptions;

  const propsData = (options.propsData = options.propsData || {});

  Object.assign(propsData, props);

  return component;
}

const RadioGroup = Vue.extend<
  object,
  object,
  object,
  { value: string | number | boolean }
>({
  name: 'RadioGroup',
  props: {
    value: {}
  },
  render(h: CreateElement) {
    const vNodes = this.$slots.default || [];

    const localInjectProps = (vNode: VNode) => {
      const nextVNode =
        vNode.tag && vNode.tag.match(/RadioGroupItem/)
          ? injectProps(vNode, {
              set: () => {
                const value = get(vNode, 'componentOptions.propsData.value');

                this.$emit('input', value);
                this.$emit('blur', value);
              },
              selected:
                this.value === get(vNode, 'componentOptions.propsData.value'),
              // @ts-expect-error acessing private property
              name: 'input_' + this._uid
            })
          : vNode;

      if (nextVNode.children) {
        nextVNode.children = nextVNode.children.map(localInjectProps);
      }

      return nextVNode;
    };

    const injectedVNodes = vNodes.map(localInjectProps);

    const focusout = () =>
      setTimeout(
        () => !this.$el.contains(document.activeElement) && this.$emit('blur'),
        200
      );

    if (injectedVNodes.length === 1) {
      const rootNode = injectedVNodes[0];

      merge(rootNode, {
        data: {
          on: {
            focusout
          },
          nativeOn: {
            focusout
          }
        }
      });

      return rootNode;
    }

    return h(
      'div',
      {
        on: {
          focusout
        }
      },
      injectedVNodes
    );
  }
});

interface RadioButtonTypes {
  props: {
    selected: boolean;
    name: string;
    set: () => void;
  };
  data: {
    attrs?: { [key: string]: any };
  };
}
const RadioButton = Vue.extend<RadioButtonTypes['props']>({
  functional: true,
  props: {
    selected: {
      required: true
    },
    name: {
      required: true
    },
    set: {
      required: true
    }
  },
  render: (
    h: CreateElement,
    { props: { selected, name, set }, data: { attrs } }: RadioButtonTypes
  ) =>
    h('input', {
      domProps: {
        checked: selected,
        name,
        type: 'radio',
        ...attrs
      },
      on: {
        click: set
      }
    })
});

interface RadioGroupItemProps {
  set: () => void;
  selected: boolean;
  value: string | boolean | number;
  label: string;
  name: string;
  disabled: boolean;
}
const RadioGroupItem = Vue.extend<object, object, object, RadioGroupItemProps>({
  name: 'RadioGroupItem',
  props: {
    set: {},
    selected: {},
    value: {},
    label: {},
    name: {},
    disabled: {}
  },
  render(h: CreateElement) {
    const scopedSlot = this.$scopedSlots.default;

    const $input = h('input', {
      attrs: { type: 'radio', name: this.name },
      domProps: { checked: this.selected },
      on: {
        click: this.set
      }
    });

    if (!scopedSlot) {
      return h('label', [$input, this.label]);
    }

    const props = {
      selected: this.selected,
      name: this.name,
      set: this.set,
      disabled: this.disabled
    };

    const listeners = {
      click: this.set
    };

    const $vnode = scopedSlot({ props, listeners, label: this.label });

    return ($vnode as VNode[]).length > 1
      ? h('div', $vnode)
      : ($vnode || [h('div')])[0];
  }
});

export { RadioGroup, RadioButton, RadioGroupItem };

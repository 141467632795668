
import Vue, { PropType } from 'vue';
import FancySelect from '@/shared/ui/forms/FancySelect';
import { School } from './';

interface Props {
  term: string;
  setTerm: (value: string | null) => void;
  clearTerm: () => void;
  disabled: boolean;
  regionId: number;
  schools: School[];
  loading: boolean;
  value: number | School;
}

export default Vue.extend<{}, {}, {}, Props>({
  name: 'SchoolLookup',
  props: {
    term: String,
    setTerm: Function,
    clearTerm: Function,
    regionId: Number,
    disabled: Boolean,
    schools: {
      type: Array as PropType<School[]>,
      default: () => []
    },
    loading: Boolean,
    value: [Number, Object]
  },
  components: {
    FancySelect
  }
});


import Vue from 'vue';
import UIButton from '@/shared/ui/buttons/Button.vue';
import { Props as KioskProps } from '../types';
import { calculateAge } from '@/shared/util';
import { getLangForRegion } from '@/shared/language/lang';

interface Data {
  lang: { [key: string]: string };
}

interface Props {
  event: KioskProps['event'];
  person: KioskProps['person'];
  markAttendance: KioskProps['markAttendance'];
  notifyParents: KioskProps['notifyParents'];
  currentRegion: KioskProps['currentRegion'];
}

interface Computed {
  isParentsShouldBeNotified: boolean;
}

interface Methods {
  markAttendanceSubmit: () => Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MarkAttendance',
  components: {
    UIButton
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion)
    };
  },
  props: {
    event: {},
    person: {},
    markAttendance: {},
    notifyParents: {},
    currentRegion: {}
  },
  computed: {
    isParentsShouldBeNotified() {
      const isParentSigned =
        this.person?.__typename === 'Person' &&
        this.person.Parents.filter(
          (parent) => parent.mediaConsentSigned && parent.dataConsentSigned
        ).length;
      return (
        calculateAge(
          this.person?.__typename === 'Person' &&
            this.person.Teen &&
            this.person.Teen.birthDate
        ) < 18 && !isParentSigned
      );
    }
  },
  methods: {
    async markAttendanceSubmit() {
      // TODO: consent flow
      // if (this.isParentsShouldBeNotified) {
      //   await this.notifyParents();
      // }
      await this.markAttendance();
    }
  }
});

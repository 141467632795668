import { computed } from 'vue';
import { compose } from 'vue-compose';
import Events from './Events.vue';
import { asLocalISO } from '@/shared/util';
import { useGetEventsQuery } from '@/generated/graphql-types';
import { getCurrentRegionEnhancer } from '@/shared/enhancers/getCurrentRegionEnhancer';
import { wrapComponent } from '@/shared/components/hoc';
import { Props } from './types';

const getEventsEnhancer = wrapComponent<
  Props,
  Pick<Props, 'loading' | 'events'>
>((props) => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 1);

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 1);

  const variables = computed(() => ({
    filter: {
      regionId: props.currentRegion,
      advisorID: [props.staff.staffID],
      range: {
        startDate: asLocalISO(startDate),
        endDate: asLocalISO(endDate)
      }
    }
  }));
  const { result, loading } = useGetEventsQuery(variables);

  return computed(() => ({
    loading: loading.value,
    events: result.value?.events || []
  }));
});

export default compose(getCurrentRegionEnhancer, getEventsEnhancer)(Events);

import Vue, { computed } from 'vue';
import autocomplete from '../autocomplete';
import SchoolLookup from './SchoolLookup.vue';
import {
  GetSchoolByRegionAndTermQuery,
  SchoolLookupByIdQuery,
  useGetSchoolByRegionAndTermQuery,
  useSchoolLookupByIdQuery
} from '@/generated/graphql-types';
import { ArrayElement } from '@/shared/util/types';
import { wrapComponent } from '../hoc';

interface SchoolAutocompleteResult {
  schools: School[];
  loading: boolean;
}

const SchoolLookupAutoComplete = autocomplete(
  wrapComponent<
    { regionId: number; value: ResolvedSchool['value']; term: string },
    SchoolAutocompleteResult
  >((props) => {
    const variables = computed(() => ({
      regionId: props.regionId,
      term: props.term
    }));
    const enabled = computed(() => !!props.term);
    const { result, loading } = useGetSchoolByRegionAndTermQuery(variables, {
      enabled
    });

    const resolvedSchool: School[] = props.value ? [props.value] : [];

    return computed(() => ({
      loading: loading.value,
      schools: [...(result.value?.schoolLookup || []), ...resolvedSchool]
    }));
  })
)(SchoolLookup);

const valueResolver = wrapComponent<{ value: number }, ResolvedSchool>(
  (props) => {
    const variables = computed(() => ({
      id: props.value
    }));
    const { result } = useSchoolLookupByIdQuery(variables);

    return computed(() => ({
      value: result.value && result.value.schoolLookupByID
    }));
  }
);

interface ResolvedSchool {
  value?: SchoolLookupByIdQuery['schoolLookupByID'] | null;
}

export default Vue.extend({
  props: ['value', 'regionId'],
  render(h) {
    if (this.value && typeof this.value !== 'object') {
      return h(valueResolver(SchoolLookupAutoComplete), {
        props: { ...this.$attrs, ...this.$props },
        on: this.$listeners
      });
    } else {
      return h(SchoolLookupAutoComplete, {
        props: { ...this.$attrs, ...this.$props },
        on: this.$listeners
      });
    }
  }
});

export type School = ArrayElement<
  GetSchoolByRegionAndTermQuery['schoolLookup']
>;

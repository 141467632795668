import Vue from 'vue';
import LogRocket from 'logrocket';
import { Staff } from '@/generated/graphql-types';

export default Vue.extend({
  props: {
    me: {
      type: Object as () => Staff
    }
  },
  mounted() {
    LogRocket.identify(String(this.me.staffID), {
      name: `${this.me.firstName} ${this.me.lastName}`
    });
  },
  render: (h) => h()
});

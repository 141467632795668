import { CompactTeen } from './shared/CompactTeen';
import every from 'lodash/every';
import trim from 'lodash/trim';
import {
  dateFormat,
  formatAddressToString,
  getCurrentEmail,
  getCurrentCellNumber,
  getCurrentLandlineNumber,
  getCurrentAddress
} from '@/shared/util';
import {
  Address,
  Teen,
  ExtendedPersonFragment,
  Gender
} from '@/generated/graphql-types';

function getGender(gender: number): Gender {
  if (gender === 1) return Gender.Female;
  return Gender.Male;
}
const currentAddress = (addresses: Address[]) =>
  getCurrentAddress(addresses || []);
const formattedAddress = (currentAddress: any) =>
  formatAddressToString(currentAddress);

export type LookupTeen = Pick<Teen, 'personID' | 'thumbnail' | '__typename'> & {
  Person: Pick<Teen['Person'], 'personID' | 'firstName' | 'lastName'>;
};

export function toCompactTeen(
  teen: Teen | ExtendedPersonFragment | LookupTeen
) {
  if (!teen) return;
  if (teen.__typename === 'Teen') {
    const teenInput = {
      birthDate: null,
      ...teen,
      Person: {
        gender: null,
        Addresses: [],
        Phones: [],
        EmailAddresses: [],
        ...teen.Person
      }
    } as Teen;
    const landLineNumber = getCurrentLandlineNumber(
      teenInput.Person.Phones || []
    );
    const cellNumber = getCurrentCellNumber(teenInput.Person.Phones || []);
    return {
      personId: teenInput.personID,
      gender: getGender(teenInput.Person.gender),
      firstName: teenInput.Person.firstName || '',
      lastName: teenInput.Person.lastName || '',
      fullName: trim(
        `${teenInput.Person.firstName || ''} ${teenInput.Person.lastName || ''}`
      ),
      birthDate: dateFormat(teenInput.birthDate),
      address: currentAddress(teenInput.Person.Addresses || []),
      landline: landLineNumber ? landLineNumber.phoneNumber : null,
      cellphone: cellNumber ? cellNumber.phoneNumber : null,
      email: (getCurrentEmail(teenInput.Person.EmailAddresses) || { email: '' })
        .email,
      thumbnail: teenInput.thumbnail || null,
      formattedAddress: formattedAddress(
        currentAddress(teenInput.Person.Addresses)
      )
    };
  } else if (teen.__typename === 'Person') {
    const landLineNumber = getCurrentLandlineNumber(teen.Phones || []);
    const cellNumber = getCurrentCellNumber(teen.Phones || []);
    return {
      personId: teen.personID,
      gender: getGender(teen.gender),
      firstName: teen.firstName || '',
      lastName: teen.lastName || '',
      fullName: trim(`${teen.firstName || ''} ${teen.lastName || ''}`),
      birthDate: null,
      address: currentAddress(teen.Addresses),
      landline: landLineNumber ? landLineNumber.phoneNumber : null,
      cellphone: cellNumber ? cellNumber.phoneNumber : null,
      email: (getCurrentEmail(teen.EmailAddresses) || { email: '' }).email,
      thumbnail: null,
      formattedAddress: formattedAddress(currentAddress(teen.Addresses))
    };
  }
}

export function toCompactTeens(
  teens: Teen[] | ExtendedPersonFragment[] | LookupTeen[]
): CompactTeen[] {
  if (teens && teens.length && every(teens)) {
    // @ts-ignore
    return teens.map((teen: Teen | ExtendedPersonFragment) =>
      toCompactTeen(teen)
    );
  }
  return [];
}

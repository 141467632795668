import Vue from 'vue';
import { provideApolloClient } from '@vue/apollo-composable';
import Vuebar from 'vuebar';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from '@/App.vue';
import client from '@/client';
import store from '@/store';

LogRocket.init('orthodox-union/attendance-app');

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  attachProps: true,
  logErrors: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'graphql-ncsy.oudev.org',
        'graphql.ncsy.org',
        'api.ncsy.org'
      ]
    }),
    new Sentry.Replay()
  ],
  environment: process.env.NODE_ENV
});

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
    return scope;
  });
});

Vue.config.errorHandler = (err, _vm, _info) => {
  Sentry.captureException(err);
  LogRocket.captureException(err);
  throw err;
};

Vue.use(Vuebar);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  provide: [provideApolloClient(client)],
  store,
  render: (h) => h(App)
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule, VuexOidcClientSettings } from 'vuex-oidc';
import config from '@/config';

const { issuer, client_id } = config;
const { protocol, hostname, port } = window.location;

const oidcSettings: VuexOidcClientSettings = {
  clientId: client_id,
  redirectUri: `${protocol}//${hostname}${port ? `:${port}` : ''}/callback`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  silentRedirectUri: `${protocol}//${hostname}${
    port ? `:${port}` : ''
  }/silent_renew.html`,
  authority: issuer,
  automaticSilentRenew: true,
  // monitorSession: false,
  // revokeAccessTokenOnSignout: true,
  postLogoutRedirectUri: `${protocol}//${hostname}${port ? `:${port}` : ''}`
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, { namespaced: true })
  }
});

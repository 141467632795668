import { computed } from 'vue';
import { compose } from 'vue-compose';
import { ApolloError } from '@apollo/client';
import Root from './Root.vue';
import { setCurrentRegionEnhancer } from '@/shared/enhancers/setCurrentRegionEnhancer';
import { getCurrentRegionEnhancer } from '@/shared/enhancers/getCurrentRegionEnhancer';
import {
  CurrentUserWithRegionsQuery,
  useCurrentUserWithRegionsQuery
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';

interface Props {
  staffLoading: boolean;
  error: ApolloError | null;
  staff: CurrentUserWithRegionsQuery['me'] | null;
}
const MainLayoutWithData = wrapComponent<{ authState: boolean }, Props>(
  (props) => {
    const enabled = computed(() => !props.authState);
    const { result, loading, error } = useCurrentUserWithRegionsQuery({
      enabled
    });

    return computed(() => ({
      staffLoading: loading.value,
      error: error.value,
      staff: result.value?.me || null
    }));
  }
);

export default compose(
  getCurrentRegionEnhancer,
  setCurrentRegionEnhancer,
  MainLayoutWithData
)(Root);

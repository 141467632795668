import { compose } from 'vue-compose';
import Vue, { Component, computed } from 'vue';
import LogRocket from 'logrocket';
import { User, UserSettings } from 'oidc-client';
import omit from 'lodash/omit';
import ncsyApi from '@/modules/ncsyApi';
import { decodeURLParams, getOptions, normalizeProps } from '@/shared/util';
import AdvisorRoot from './AdvisorRoot.vue';
import { Event } from './types';
import {
  MarkAttendanceMutation,
  MarkAttendanceMutationVariables,
  MarkStaffAttendanceMutation,
  MarkStaffAttendanceMutationVariables,
  RemoveAttendanceMutation,
  RemoveAttendanceMutationVariables,
  RemoveStaffAttendanceMutation,
  RemoveStaffAttendanceMutationVariables,
  useMarkAttendanceMutation,
  useMarkStaffAttendanceMutation,
  useRemoveAttendanceMutation,
  useRemoveStaffAttendanceMutation
} from '@/generated/graphql-types';
import { MutateFunction } from '@vue/apollo-composable';
import { RecordPropsDefinition } from 'vue/types/options';
import store from '@/store';
import { setCurrentRegionEnhancer } from '@/shared/enhancers/setCurrentRegionEnhancer';
import { wrapComponent } from '@/shared/components/hoc';

interface DecodedTokenResponse {
  Active: boolean;
  RegionId: number;
  TokenResponse: UserSettings;
  Event: Event;
}

interface Data {
  isLoading: boolean;
  isError: boolean;
  token: string | null;
  eventData: null;
  eventLoading: boolean;
}

interface Methods {
  getEvent: () => Promise<any>;
}

export interface Props {
  queryString: string;
  event: Event;
  eventId: number;
  teen: any;
  staff: any;
  attendeesLoading: boolean;
  setCurrentRegion: (regionId: number) => void;
  error: boolean;
  userLoading: boolean;
  markAttendance: (
    eventId: number,
    personId: number
  ) => ReturnType<
    MutateFunction<MarkAttendanceMutation, MarkAttendanceMutationVariables>
  >;
  markAttendanceLoading: boolean;
  markStaffAttendance: (
    eventId: number,
    staffId: number
  ) => ReturnType<
    MutateFunction<
      MarkStaffAttendanceMutation,
      MarkStaffAttendanceMutationVariables
    >
  >;
  markStaffAttendanceLoading: boolean;
  removeAttendance: (
    attendanceId: number
  ) => ReturnType<
    MutateFunction<RemoveAttendanceMutation, RemoveAttendanceMutationVariables>
  >;
  removeAttendanceLoading: boolean;
  removeStaffAttendance: (
    eventId: number,
    staffId: number
  ) => ReturnType<
    MutateFunction<
      RemoveStaffAttendanceMutation,
      RemoveStaffAttendanceMutationVariables
    >
  >;
  removeStaffAttendanceLoading: boolean;
}

const getEvent = async (eventId: number, token: string) => {
  const result = await ncsyApi(
    'authentication/attendanceappadvisor',
    {
      token,
      eventId
    },
    'post'
  );

  return result.data;
};

const getUserEnhancer = (Component: Component) => {
  const props = omit({ ...normalizeProps(getOptions(Component).props) }, [
    'event'
  ]) as RecordPropsDefinition<Props>;
  return Vue.extend<Data, Methods, object, Props>({
    name: 'WithUser',
    props,
    data() {
      return {
        isLoading: true,
        isError: false,
        token: null,
        eventData: null,
        eventLoading: false
      };
    },
    async created() {
      this.isLoading = true;
      this.token = decodeURLParams(this.$attrs.queryString).token;
      const result: DecodedTokenResponse = await this.getEvent();
      const user = new User({ ...result.TokenResponse });
      await store.dispatch('oidcStore/storeOidcUser', user);
      LogRocket.identify('attendanceapp@ou.org', {
        name: 'Attendance App User'
      });
      await this.setCurrentRegion(result.RegionId);
      if (result.Active === false) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    methods: {
      async getEvent() {
        this.eventLoading = true;
        const result = await getEvent(this.eventId, this.token || '');
        this.eventData = result.Event;
        this.eventLoading = false;
        return result;
      }
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          userLoading: this.isLoading,
          error: this.isError,
          event: this.eventData,
          attendeesLoading: this.eventLoading
        },
        on: {
          refreshEvent: () => {
            this.getEvent();
          }
        }
      });
    }
  });
};

const markAttendanceEnhancer = wrapComponent<object, Props>((_props) => {
  const { mutate: markAttendance, loading } = useMarkAttendanceMutation();

  return computed(() => ({
    markAttendanceLoading: loading.value,
    markAttendance: (eventId: number, personId: number) =>
      markAttendance({ input: { eventId, teenId: personId } })
  }));
});

const markStaffAttendanceEnhancer = wrapComponent<object, Props>((_props) => {
  const { mutate: markStaffAttendance, loading } =
    useMarkStaffAttendanceMutation();

  return computed(() => ({
    markStaffAttendanceLoading: loading.value,
    markStaffAttendance: (eventId: number, staffId: number) =>
      markStaffAttendance({ eventId, staffId })
  }));
});

const removeAttendanceEnhancer = wrapComponent<object, Props>((_props) => {
  const { mutate: removeAttendance, loading } = useRemoveAttendanceMutation();

  return computed(() => ({
    removeAttendanceLoading: loading.value,
    removeAttendance: (attendanceId: number) =>
      removeAttendance({ attendanceId })
  }));
});

const removeStaffAttendanceEnhancer = wrapComponent<object, Props>((_props) => {
  const { mutate: removeStaffAttendance, loading } =
    useRemoveStaffAttendanceMutation();

  return computed(() => ({
    removeStaffAttendanceLoading: loading.value,
    removeStaffAttendance: (eventId: number, staffId: number) =>
      removeStaffAttendance({ eventId, staffId })
  }));
});

export default compose(
  setCurrentRegionEnhancer,
  getUserEnhancer,
  markAttendanceEnhancer,
  markStaffAttendanceEnhancer,
  removeAttendanceEnhancer,
  removeStaffAttendanceEnhancer
)(AdvisorRoot);

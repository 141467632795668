import { Component, ComponentPropsOptions, defineComponent, h, Ref } from 'vue';
import { getOptions, normalizeProps } from '../util';

export const wrapComponent = <
  InnerProps extends Record<string, any>,
  OuterProps extends Record<string, any>
>(
  outerPropsFn: (props: InnerProps) => Ref<Partial<OuterProps>>
) => {
  return (WrappedComponent: Component) => {
    const childProps: ComponentPropsOptions<InnerProps> = normalizeProps(
      getOptions(WrappedComponent).props
    );
    const name = getOptions(WrappedComponent).name;

    return defineComponent({
      name: `${name}WithApollo`,
      props: childProps,
      setup(props, { attrs, listeners, slots }) {
        // @ts-expect-error ExtractPropTypes infer required props as undefined
        const outerProps = outerPropsFn(props);

        return () =>
          h(WrappedComponent, {
            on: listeners,
            attrs,
            props: {
              ...props,
              ...outerProps.value
            },
            scopedSlots: slots
          });
      }
    });
  };
};

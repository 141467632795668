
import Vue from 'vue';

interface Data {
  listeners: Record<string, Function | Function[]>;
}

interface Props {
  color: string;
  set: () => void;
  selected: boolean;
  disabled: boolean;
  name: string;
  labelText: string;
}

export default Vue.extend<Data, {}, {}, Props>({
  name: 'UIRadio',
  inheritAttrs: false,
  props: {
    color: String,
    set: Function,
    selected: Boolean,
    disabled: { type: Boolean, default: false },
    name: String,
    labelText: String
  },
  methods: {
    input($event: InputEvent) {
      this.$emit('input', ($event.target as HTMLInputElement).checked);
    }
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    }
  }
});

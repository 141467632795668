
import Vue from 'vue';
import Loading from '@/shared/components/Loading.vue';
import { getLangForRegion } from '@/shared/language/lang';
import Kiosk from '../components/Kiosk';
import { Data, Methods, Props } from './types';

export default Vue.extend<Data, Methods, {}, Props>({
  name: 'TeenRoot',
  data() {
    return {
      lang: getLangForRegion(this.currentRegion)
    };
  },
  components: {
    Kiosk,
    Loading
  },
  props: {
    queryString: {},
    seriesId: {},
    eventId: {},
    setCurrentRegion: {},
    error: {},
    userLoading: {},
    externalEmail: {},
    currentRegion: {},
    noRelevantEvents: {}
  },
  methods: {
    formatDate(date) {
      const time = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      });

      const formattedDate = date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long'
      });

      return `${time} on ${formattedDate}`;
    }
  }
});


import Vue from 'vue';
import sortBy from 'lodash/sortBy';
import { RouterLink, Route } from 'vue-component-router';
import { directive as onClickaway } from 'vue-clickaway2';

import UISelect from '@/shared/ui/forms/Select.vue';
import Loading from '@/shared/components/Loading.vue';
import Logrocket from '@/shared/components/Logrocket';
import Popover from '@/shared/ui/popover/Popover.vue';
import fullheight from '@/shared/directives/fullheight';
import ScrollablePanel from '@/shared/components/scrollable-panel.vue';
import Events from './pages/Events';
import Kiosk from '../components/Kiosk';
import { Data, Methods, Computed, Props } from './types';
import store from '@/store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlusCircle, faClipboard } from '@fortawesome/pro-solid-svg-icons';
import {
  faAngleDown,
  faIndent,
  faOutdent
} from '@fortawesome/pro-regular-svg-icons';

import { getLangForRegion } from '@/shared/language/lang';
import { isJSU } from '@/shared/util';
import {
  AdvisorRegionFragment,
  PermissionEnum
} from '@/generated/graphql-types';

library.add(faPlusCircle, faClipboard);
library.add(faIndent, faOutdent, faAngleDown);

const MainLayout = Vue.extend<Data, Methods, Computed, Props>({
  name: 'MainLayout',
  components: {
    Logrocket,
    Route,
    RouterLink,
    Loading,
    UISelect,
    Popover,
    ScrollablePanel,
    Events,
    Kiosk
  },
  directives: {
    onClickaway,
    fullheight
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  props: {
    authState: {},
    currentRegion: {},
    setCurrentRegion: {},
    staffLoading: {},
    staff: {},
    error: {},
    eventId: {}
  },
  data() {
    return {
      PermissionEnum,
      width: 0,
      userOpen: false,
      lang: getLangForRegion(this.currentRegion)
    };
  },
  computed: {
    isJSU() {
      return isJSU(window.location.host);
    },
    availableRegions() {
      const regions =
        this.staff &&
        (this.staff.AdvisorRegions || [])
          .filter(
            (x) =>
              x.active &&
              (x.claims || []).find((p) => p === PermissionEnum.AccessSite)
          )
          .map((x) => x.Region);
      return sortBy(regions, ['regionName']);
    },
    currentRegionName() {
      const region = this.availableRegions.find(
        (region) => region.regionId === this.currentRegion
      );
      return region ? region.regionName : 'No region selected';
    },
    currentAdvisorRegion() {
      return (
        (this.staff &&
          this.currentRegion &&
          this.staff.AdvisorRegions.find(
            (a) => a.Region.regionId === this.currentRegion
          )) ||
        ({ claims: [] } as unknown as AdvisorRegionFragment)
      );
    }
  },
  methods: {
    userToggle() {
      this.userOpen = this.userOpen ? false : true;
    },

    logout() {
      store.dispatch('oidcStore/signOutOidc');
    },

    handleResize() {
      this.width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
    }
  },
  watch: {
    availableRegions: {
      handler(regions: Array<{ regionId: number }>) {
        if (regions && regions.length === 1) {
          this.setCurrentRegion(regions[0].regionId);
        }
      },
      immediate: true
    }
  }
});

export default MainLayout;

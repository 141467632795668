
import Vue from 'vue';
import UIButton from '@/shared/ui/buttons/Button.vue';
import { getLangForRegion } from '@/shared/language/lang';
import { Props as KioskProps } from '../types';
import { GetEventByIdQuery } from '@/generated/graphql-types';

interface Data {
  lang: { [key: string]: string };
  selectedTicket: number | null;
}

interface Props {
  tickets: GetEventByIdQuery['event']['EventTickets'];
  currentRegion: KioskProps['currentRegion'];
}

interface Methods {
  selectTicket: (ticketId: number) => void;
  confirmTicket: () => void;
}

export default Vue.extend<Data, Methods, {}, Props>({
  components: { UIButton },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      selectedTicket: null
    };
  },
  props: {
    tickets: {},
    currentRegion: {}
  },
  methods: {
    selectTicket(ticketId) {
      this.selectedTicket = ticketId;
    },
    confirmTicket() {
      this.$emit('ticketSelected', this.selectedTicket);
    }
  }
});

import { ApolloCache } from '@apollo/client';
import {
  GetCurrentRegionDocument,
  GetCurrentRegionQuery,
  GetCurrentRegionQueryVariables
} from '@/generated/graphql-types';

export const resolvers = {
  Mutation: {
    setCurrentRegion: (
      _: any,
      { currentRegion }: { currentRegion: number },
      { cache }: { cache: ApolloCache<any> }
    ) => {
      sessionStorage.setItem(
        'currentRegion',
        currentRegion ? `${currentRegion}` : ''
      );
      cache.writeQuery<GetCurrentRegionQuery, GetCurrentRegionQueryVariables>({
        query: GetCurrentRegionDocument,
        data: { currentRegion }
      });
    }
  },
  Query: {
    currentRegion: () => {
      return sessionStorage.getItem('currentRegion') || null;
    }
  }
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "EntityUnion": [
      "Person",
      "Staff"
    ],
    "EventParticipant": [
      "EventGuest",
      "EventStaff",
      "Registration"
    ],
    "GlobalSearchResult": [
      "Event",
      "Family",
      "Teen"
    ],
    "GoalEntity": [
      "EventSubType",
      "EventTrack",
      "EventType",
      "InteractionType",
      "School",
      "SchoolType"
    ]
  }
};
      export default result;
    
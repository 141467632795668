import TeenRoot from './TeenRoot.vue';
import Vue, { Component } from 'vue';
import { compose } from 'vue-compose';
import LogRocket from 'logrocket';
import { decodeURLParams, getOptions, normalizeProps } from '@/shared/util';
import { User, UserSettings } from 'oidc-client';
import ncsyApi from '@/modules/ncsyApi';
import store from '@/store';
import { getCurrentRegionEnhancer } from '@/shared/enhancers/getCurrentRegionEnhancer';
import { setCurrentRegionEnhancer } from '@/shared/enhancers/setCurrentRegionEnhancer';
import { Props } from './types';

interface DecodedTokenResponse {
  Active: boolean;
  LinkActiveFrom: string;
  LinkActiveUntil: string;
  RegionId: number;
  TokenResponse: UserSettings;
  Email?: string;
  EmailFound: boolean;
}

const getAuthToken = async ({
  emailToken,
  token
}: {
  emailToken: string | undefined;
  token: string;
}) => {
  const result = await ncsyApi(
    'authentication/attendanceapp',
    {
      token,
      emailToken
    },
    'post'
  );

  return result.data;
};

type Data = {
  isLoading: boolean;
  isError: boolean;
  email: string | null;
  linkActiveFrom: Date | null;
};

const getUserEnhancer = (Component: Component) => {
  const props = { ...normalizeProps(getOptions(Component).props) };
  return Vue.extend<Data, object, object, Props>({
    name: 'WithUser',
    props,
    data() {
      return {
        isLoading: true,
        isError: false,
        email: null,
        linkActiveFrom: null
      };
    },
    async created() {
      this.isLoading = true;
      const token = decodeURLParams(this.queryString).token;
      const emailToken = decodeURLParams(this.queryString).email;
      const result: DecodedTokenResponse = await getAuthToken({
        token,
        emailToken
      });
      const user = new User({ ...result.TokenResponse });
      if (result.Email) {
        this.email = result.Email;
      }
      await store.dispatch('oidcStore/storeOidcUser', user);
      LogRocket.identify('attendanceapp@ou.org', {
        name: 'Attendance App User'
      });
      await this.setCurrentRegion(result.RegionId);
      if (result.Active === false) {
        this.linkActiveFrom = new Date(result.LinkActiveFrom + 'Z');

        this.isError = true;
      }
      this.isLoading = false;
    },
    render(h) {
      return h(Component, {
        props: {
          ...this.$props,
          userLoading: this.isLoading,
          error: this.isError,
          externalEmail: this.email,
          activeFrom: this.linkActiveFrom
        },
        on: this.$listeners
      });
    }
  });
};

export default compose(
  getCurrentRegionEnhancer,
  setCurrentRegionEnhancer,
  getUserEnhancer
)(TeenRoot);


import Vue from 'vue';
import SimpleForm, { Field, Error, SubmitCallbackArgs } from 'vue-simpleform';
import { splitName, validateName } from '@/shared/util';
import UIButton from '@/shared/ui/buttons/Button.vue';
import UIInput from '@/shared/ui/forms/Input.vue';
import { GetParentsFormObject, Props as KioskProps } from '../types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { getLangForRegion } from '@/shared/language/lang';
import { UpdateTeenField } from '@/generated/graphql-types';

library.add(faCheck);

interface Data {
  formObject: GetParentsFormObject;
  isDisabled: boolean;
  lang: { [k: string]: string };
}

interface Props {
  currentRegion: KioskProps['currentRegion'];
  email: KioskProps['email'];
  person: KioskProps['person'];
  updateTeen: KioskProps['updateTeen'];
}

interface Methods {
  updateParentInformationHandler: (
    callbackArgs: SubmitCallbackArgs<GetParentsFormObject>
  ) => Promise<void>;
  validate: (values: GetParentsFormObject) => Errors<GetParentsFormObject>;
}
type Errors<T> = {
  [k in keyof T]?: string;
};
export default Vue.extend<Data, Methods, {}, Props>({
  name: 'GetParentData',
  components: {
    UIInput,
    UIButton,
    SimpleForm,
    Field,
    Error
  },
  data() {
    return {
      lang: getLangForRegion(this.currentRegion),
      formObject: {
        fatherName: null,
        fatherEmail: null,
        motherName: null,
        motherEmail: null
      },
      isDisabled: true
    };
  },
  props: {
    currentRegion: {},
    email: {},
    person: {},
    updateTeen: {}
  },
  methods: {
    async updateParentInformationHandler(
      callbackArgs: SubmitCallbackArgs<GetParentsFormObject>
    ) {
      if ('errors' in callbackArgs) return;
      if (callbackArgs.setSubmitting) callbackArgs.setSubmitting();

      const { fatherName, fatherEmail, motherName, motherEmail } =
        callbackArgs.values;

      if (fatherName && fatherEmail) {
        await this.updateTeen(
          UpdateTeenField.FatherInfo,
          JSON.stringify({
            value: JSON.stringify({
              name: splitName(callbackArgs.values.fatherName as string),
              email: fatherEmail
            })
          })
        );
      }

      if (motherName && motherEmail) {
        await this.updateTeen(
          UpdateTeenField.MotherInfo,
          JSON.stringify({
            value: JSON.stringify({
              name: splitName(callbackArgs.values.motherName as string),
              email: motherEmail
            })
          })
        );
      }
    },
    validate(values: GetParentsFormObject) {
      const errors: Errors<GetParentsFormObject> = {};
      if (
        (values.fatherName &&
          !validateName(values.fatherName) &&
          !values.fatherEmail) ||
        (values.fatherEmail && !validateName(values.fatherName))
      ) {
        errors.fatherName = 'Please enter the father’s first and last name';
      }
      if (values.fatherName && !values.fatherEmail) {
        errors.fatherEmail = 'Please enter the father’s email';
      }
      if (
        (values.motherName &&
          !validateName(values.motherName) &&
          !values.motherEmail) ||
        (values.motherEmail && !validateName(values.motherName))
      ) {
        errors.motherName = 'Please enter the mother’s first and last name';
      }
      if (values.motherName && !values.motherEmail) {
        errors.motherEmail = 'Please enter the mother’s email';
      }
      if (this.email?.toLowerCase() === values.fatherEmail?.toLowerCase()) {
        errors.fatherEmail = 'Email is belongs to teen';
      }
      if (this.email?.toLowerCase() === values.motherEmail?.toLowerCase()) {
        errors.motherEmail = 'Email is belongs to teen';
      }
      this.isDisabled =
        Object.keys(errors).length > 0 ||
        (!values.fatherName &&
          !values.fatherEmail &&
          !values.motherEmail &&
          !values.motherName);
      return errors;
    }
  }
});

import { computed } from 'vue';
import { compose } from 'vue-compose';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import StaffLookup from './StaffLookup.vue';
import {
  GetStaffQuery,
  GetStaffQueryVariables,
  useGetStaffQuery
} from '@/generated/graphql-types';
import { wrapComponent } from '@/shared/components/hoc';
import { ArrayElement } from '@/shared/util/types';

interface Props {
  regionId: number | null;
}

interface StaffByRegionProps {
  staff: ArrayElement<GetStaffQuery['staff']>['Staff'][];
  loading: boolean;
}

const staffLookupEnhancer = wrapComponent<Props, StaffByRegionProps>(
  (props) => {
    const variables = computed<GetStaffQueryVariables>(() => ({
      filter: {
        regionID: props.regionId,
        active: true
      }
    }));
    const enabled = computed(() => !!props.regionId);
    const { result, loading } = useGetStaffQuery(variables, { enabled });

    return computed(() => ({
      loading: loading.value,
      staff: result.value
        ? uniq(
            orderBy(
              result.value.staff.map((x) => x.Staff),
              ['lastName'],
              ['asc']
            )
          )
        : []
    }));
  }
);

export default compose(staffLookupEnhancer)(StaffLookup);


import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

library.add(faCheck);

export default Vue.extend({
  name: 'UICheckbox',
  props: {
    value: {
      type: Boolean
    },
    labelText: {
      type: String
    }
  },
  methods: {
    onChange($event: InputEvent) {
      this.$emit('input', ($event.target as HTMLInputElement).checked);
      this.$emit('blur', ($event.target as HTMLInputElement).checked);
    }
  }
});

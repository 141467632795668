
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import Loading from '@/shared/components/Loading.vue';
import ScrollablePanel from '@/shared/components/scrollable-panel.vue';
import fullheight from '@/shared/directives/fullheight';
import PersonCard from './components/PersonCard';
import StaffLookup from '@/shared/components/StaffLookup';
import TeenLookup from '@/shared/components/TeenLookup';
import UIButton from '@/shared/ui/buttons/Button.vue';
import { isJSU, normalizedEventDateTime } from '@/shared/util';
import { Attendee, EventStaff } from './types';
import { Props as ExternalProps } from './';
import { CompactTeen } from '@/shared/components/TeenLookup/shared/CompactTeen';
import { GetStaffQuery } from '@/generated/graphql-types';
import { ArrayElement } from '@/shared/util/types';

interface Data {
  staffSelected: ArrayElement<GetStaffQuery['staff']>['Staff'] | null;
  teenSelected: CompactTeen | null;
  markingTeenAttendance: boolean;
  markingStaffAttendance: boolean;
}

interface Computed {
  isJSU: boolean;
  staffParticipating: EventStaff[];
  teensOrdered: Attendee[];
  filterStaffIds: number[];
  filterTeenIds: number[];
  eventDate: string;
  teensTotal: number;
  teensAttended: number;
}

type Props = Pick<
  ExternalProps,
  | 'eventId'
  | 'event'
  | 'attendeesLoading'
  | 'error'
  | 'userLoading'
  | 'setCurrentRegion'
  | 'markAttendance'
  | 'markAttendanceLoading'
  | 'markStaffAttendance'
  | 'markStaffAttendanceLoading'
  | 'removeAttendance'
  | 'removeAttendanceLoading'
  | 'removeStaffAttendance'
  | 'removeStaffAttendanceLoading'
>;

export default Vue.extend<Data, {}, Computed, Props>({
  components: {
    Loading,
    PersonCard,
    RouterLink,
    ScrollablePanel,
    StaffLookup,
    TeenLookup,
    UIButton
  },
  directives: {
    fullheight
  },
  data() {
    return {
      staffSelected: null,
      teenSelected: null,
      markingStaffAttendance: false,
      markingTeenAttendance: false
    };
  },
  props: {
    eventId: {},
    event: {},
    attendeesLoading: {},
    error: {},
    userLoading: {},
    setCurrentRegion: {},
    markAttendance: {},
    markAttendanceLoading: {},
    markStaffAttendance: {},
    markStaffAttendanceLoading: {},
    removeAttendance: {},
    removeAttendanceLoading: {},
    removeStaffAttendance: {},
    removeStaffAttendanceLoading: {}
  },
  computed: {
    isJSU() {
      return isJSU(
        window.location.host,
        this.event && this.event.eventSubTypeID
      );
    },
    staffParticipating() {
      return ((this.event && this.event.EventStaffs) || [])
        .filter((es) => es.ParticipationStatus === 1)
        .sort((a, b) => {
          return a.Staff.LastName > b.Staff.LastName ? 1 : -1;
        });
    },
    teensOrdered() {
      return this.event.attendees.sort((a, b) => {
        return a.Teen.Person.LastName > b.Teen.Person.LastName ? 1 : -1;
      });
    },
    teensTotal() {
      return this.event.attendees.length;
    },
    teensAttended() {
      return this.event.attendees.filter((t) => t.Status > 0).length;
    },
    filterStaffIds() {
      return (this.event.EventStaffs || []).map((es) => es.personID);
    },
    filterTeenIds() {
      return (this.event.attendees || []).map((a) => a.personID);
    },
    eventDate() {
      return normalizedEventDateTime(
        this.event.startDate,
        this.event.endDate,
        this.event.TimeZone
      );
    }
  },
  methods: {
    async handleMarkAttendance() {
      this.markingTeenAttendance = true;
      try {
        await this.markAttendance(
          this.event.eventID,
          this.teenSelected!.personId
        );
        this.$emit('refreshEvent');
        this.markingTeenAttendance = false;
        this.teenSelected = null;
      } catch (e) {
        this.markingTeenAttendance = false;
        throw e;
      }
    },
    async handleMarkStaffAttendance() {
      if (!this.staffSelected) {
        return;
      }
      this.markingStaffAttendance = true;
      try {
        await this.markStaffAttendance(
          this.event.eventID,
          this.staffSelected.staffID
        );
        this.$emit('refreshEvent');
        this.markingStaffAttendance = false;
        this.staffSelected = null;
      } catch (e) {
        this.markingStaffAttendance = false;
        throw e;
      }
    }
  }
});

import { computed } from 'vue';
import {
  SetCurrentRegionMutation,
  SetCurrentRegionMutationVariables,
  useSetCurrentRegionMutation
} from '@/generated/graphql-types';
import { MutateFunction } from '@vue/apollo-composable';
import { wrapComponent } from '@/shared/components/hoc';

type Props = {
  loading: boolean;
  setCurrentRegion: (
    regionId: number
  ) => ReturnType<
    MutateFunction<SetCurrentRegionMutation, SetCurrentRegionMutationVariables>
  >;
};

export const setCurrentRegionEnhancer = wrapComponent<object, Props>(
  (_props) => {
    const { mutate: setCurrentRegion, loading } = useSetCurrentRegionMutation();

    return computed(() => ({
      loading: loading.value,
      setCurrentRegion: (regionId: number) => {
        return setCurrentRegion({ currentRegion: regionId });
      }
    }));
  }
);

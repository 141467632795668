
import Vue from 'vue';
import Loading from '@/shared/components/Loading.vue';
import EventCard from './components/EventCard.vue';
import fullheight from '@/shared/directives/fullheight';
import ScrollablePanel from '@/shared/components/scrollable-panel.vue';
import { getLangForRegion } from '@/shared/language/lang';

import { Props } from './types';

export default Vue.extend<{}, {}, {}, Props>({
  name: 'Events',
  data() {
    return {
      lang: getLangForRegion(this.currentRegion)
    };
  },
  components: {
    Loading,
    EventCard,
    ScrollablePanel
  },
  directives: {
    fullheight
  },
  props: {
    staff: {},
    loading: {},
    events: {},
    currentRegion: {}
  }
});

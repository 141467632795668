
import Vue from 'vue';
import { Props as KioskProps } from '../types';
import { getLangForRegion } from '@/shared/language/lang';

interface Props {
  event: KioskProps['event'];
  person: KioskProps['person'];
  currentRegion: KioskProps['currentRegion'];
}

export default Vue.extend<{}, {}, {}, Props>({
  name: 'Finished',
  data() {
    return {
      lang: getLangForRegion(this.currentRegion)
    };
  },
  props: {
    event: {},
    person: {},
    currentRegion: {}
  }
});
